<template>
  <div class="row mb-3">
    <epi :intEpiId="0" :intTipoEquipamentoId="1341" :key="$root.$session.versao" />
  </div>
</template>

<script>
import Epi from "@/components/documento/pgr/equipamento/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { Epi },
  beforeDestroy() {},
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
